import React, {Component,useState  } from 'react'
import { NavLink,Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';

import { connect } from 'react-redux'
import { firestoreConnect } from  'react-redux-firebase'
import { compose } from 'redux'
import firebase from 'firebase/compat/app'
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';

import {baseAPIURL} from "../../store/api/Access"

import  { useEffect  } from 'react';
import  axios  from 'axios'


import * as Icons from '@mui/icons-material';

import Page404 from '../Page404';
import Loader from "../../components/other/Loader"

const iconArray =[
  "Home",
  "AccountBalance",

]


const Index = ({user,}) => {

    
  const [name, setName] = React.useState("");
  const [icon, seticon] = React.useState("");
  const [formError, setFormError] = React.useState(null);

    const [{loading,loadingMore,error}, setStatus] = React.useState({loading:true});
  
    const navigate = useNavigate();

      const handleAdd = () => {

        if(!name || name == "")
        {
          setFormError(true);
          return;
        }

          setStatus({loading:true});
          firebase.auth().currentUser.getIdToken().then(function(idToken) {

            let data = JSON.stringify({
              "name": name,
              "icon": icon
            });

            let config = {
              method: 'post',
              maxBodyLength: Infinity,
              url: baseAPIURL+'group/add',
              headers: { 
                'Authorization': idToken, 
                'Content-Type': 'application/json'
              },
              data : data
            };
            
            axios.request(config).then(Result => {
                                    
                console.log(Result)
                if(Result.data.error)
                {
                    setStatus({error:true});
                }
                else if(Result.data.success)
                {
                  setStatus({});
                  navigate("/group/"+Result.data.id);
                }
                else
                {
                  setStatus({error:true});
                }
              
          }).catch(err => {
                console.error("handleSubmit")
                console.error(err)
                setStatus({error:true});
          });
        });
          
      };
  
  
        return (
            <main style={{margin:"30px"}}>
                <Card>
                    <CardContent >
                      <h2>Add Group</h2>
                      <Box>
                        <TextField error={formError} value={name} onChange={(e)=>{setName(e.target.value);setFormError(false);}} helperText={formError ? "Cannot be blank.":""} id="fName" label="Group Name" fullWidth variant="outlined" />
                      </Box>


                      <Box>

                        <h3 style={{marginBottom:0}}>Icon</h3>
                        <p style={{marginTop:0}}>Select icon</p>

                        <Grid container spacing={2}>

                        <Grid item sm={3} lg={1} md={1} xs={4} >
                            <Button variant={!icon || icon==""?"outlined":"Text"} onClick={()=>seticon("")}sx={{textAlign:"center",width:"100%", height:"60px",borderColor:"#02af48ff"}} >
                                <Box>
                                    <p  style={{ fontSize: 20, color:"#02af48ff"}}>
                                      None
                                    </p>
                                </Box>
                            </Button>
                        </Grid>

                      {iconArray && iconArray.map((row, index) => {
                                const IconComponent = Icons[row]
                                return(
                                    <Grid item sm={3} lg={1} md={1} xs={4} >
                                        <Button variant={icon && icon==row?"outlined":"Text"} onClick={()=>seticon(row)}sx={{textAlign:"center",width:"100%", height:"60px",borderColor:"#02af48ff"}} >
                                            <Box>
                                                <IconComponent  sx={{ fontSize: 40, color:"#02af48ff"}}/>
                                            </Box>
                                        </Button>
                                    </Grid>)
                            })}
                        </Grid>

                      </Box>

                      <Box sx={{mt:3, textAlign:"right"}}>
                          <Button onClick={handleAdd} variant="contained"  sx={{textAlign:"center"}} >
                                  Add
                          </Button>
                      </Box>

                    </CardContent>
                </Card>
            </main>
        );
}



const mapStateToProps = (state) => {
    return {
        user:firebase.auth().currentUser,
    }
}

export default compose(
    connect(mapStateToProps)
)(Index);
import React from 'react'

import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';


const contact = () => {
    
        return (

            <Container>
        <Card  style={{textAlign:"center", marginTop:"30px"}}>
        <CardContent sx={{textAlign:"center", }}>

            <SentimentDissatisfiedIcon  sx={{ fontSize: 50, color:"#02af48ff"}}/>
            
            <h3>Could not Load.</h3>
                <p>Please try again later.</p>
            
            </CardContent>
                            </Card>
                            </Container>

        )
    
}
export default contact

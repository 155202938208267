import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import firebase from 'firebase/compat/app'
import {useRef} from 'react';
import { NavLink,Link } from 'react-router-dom'
import  axios  from 'axios'

import AppBar from '@mui/material/AppBar';
import {Box,Badge} from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import MenuList from '@mui/material/MenuList';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import PersonIcon from '@mui/icons-material/Person';
import AppsIcon from '@mui/icons-material/Apps';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MenuIcon from '@mui/icons-material/Menu';
import {baseAPIURL} from '../../store/api/Access';

const Header = ({user}) => {    
    
    const [menuActice, setMenuActice] = useState(null);
    const [isWideView, setIsWideView] = useState(window.innerWidth );
    const [notificationsCount, setNotificationsCount] = useState(0);

    
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        if(menuActice)
        {
            setMenuActice(null)
            return
        }
        setMenuActice(event.currentTarget);
      };

    const handleClose = () => {
        setMenuActice(null)
    };


  
    const LoadData = () => {
        firebase.auth().currentUser.getIdToken().then(function(idToken) {

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: baseAPIURL+'notifications/count',
                headers: { 
                    'Authorization': idToken, 
                    'Content-Type': 'application/json'
                },
                data : "{}"
            };
            
            axios.request(config).then(Result => {
                if("count" in Result.data)
                {
                    setNotificationsCount(Result.data.count);
                }
            }).catch(err => {});
        });
        
    };
    
    


    const handleResize = () => {
        setIsWideView(window.innerWidth);
        setMenuActice(false)
    };


    
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize();
        LoadData();
        const interval = setInterval(LoadData, 30000);

        return () => {
            clearInterval(interval)
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    

    /*
    if(isWideView)
    {
        return null
    }*/

    return (
        <Box >
            <AppBar position="static">
                <Toolbar className='Header'>
                        <Typography className='Title' variant="h6" component="div" >
                            Blizo
                        </Typography>
                    
                    <NavLink to="/">
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ml:1}}
                            className='AppsIcon'
                        >
                            <AppsIcon />
                        </IconButton>
                    </NavLink>

                        <Box  sx={{ flexGrow: 1 }}/>
                    


                        {isWideView>500 ?
                        <NavLink to="/notifications">
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            className='NotificationsIcon'
                        >
                            <Badge badgeContent={notificationsCount} color="error">

                                <NotificationsIcon />
                            </Badge>
                        </IconButton>
                    </NavLink> 
 : null}



                    
                        <IconButton
                            id="Menu-Button-Id"
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            className='NotificationsIcon'
                            onClick={handleClick}
                        >
                            <MenuIcon />
                        </IconButton>

                        <Menu
                            id="basic-menu"
                            anchorEl={menuActice}
                            open={(Boolean(menuActice) && isWideView>500)}
                            onClose={handleClose}
                        >
                        <MenuItem component={NavLink} to="/files" onClick={handleClose}>Files</MenuItem>
                        <MenuItem component={NavLink} to="/schedule" onClick={handleClose}>Schedule</MenuItem>
                        <MenuItem component={NavLink} to="/components" onClick={handleClose}>Components</MenuItem>
                            
                            <Divider />
                            <MenuItem component={NavLink} to="/settings" onClick={handleClose}>Settings</MenuItem>
                            <MenuItem component={NavLink} to="/notifications" onClick={handleClose}>Notifications</MenuItem>
                            <Divider />
                            <MenuItem component={NavLink} to="/logout" onClick={handleClose}>Logout</MenuItem>
                        </Menu>

                </Toolbar>
            </AppBar>
                
            {isWideView<=500 && Boolean(menuActice) ?
                <MenuList dense sx={{background:"#fff", topBoarder:"solid #000 1px"}}>
                    
                    <Divider />

                    <MenuItem component={NavLink} to="/files" onClick={handleClose}><ListItemText>Files</ListItemText></MenuItem>
                    <MenuItem component={NavLink} to="/schedule"  onClick={handleClose}><ListItemText>Schedule</ListItemText></MenuItem>
                    <MenuItem component={NavLink} to="/components"  onClick={handleClose}><ListItemText>Components</ListItemText></MenuItem>

                    <Divider />
                    
                    <MenuItem component={NavLink} to="/settings" onClick={handleClose}><ListItemText>Settings</ListItemText></MenuItem>
                    <MenuItem component={NavLink} to="/notifications" onClick={handleClose}><ListItemText>Notifications</ListItemText></MenuItem>

                    <Divider />
                    
                    <MenuItem component={NavLink} to="/logout" onClick={handleClose}><ListItemText>Logout</ListItemText></MenuItem>
                </MenuList>

                :null
            }
        </Box>
    );
}

const mapStateToProps = (state) => {
    return {
        user:firebase.auth().currentUser
    }
}

export default connect(mapStateToProps)(Header)
import {baseAPIURL} from "../api/Access"
import axios from "axios"

import firebase from 'firebase/compat/app'
import { useSelector, useDispatch } from 'react-redux';

import { getAuth } from "firebase/auth";


export const GetProfile = () => {

        const dispatch = useDispatch();
        //const pageDetails = useSelector(state => state.pageDetails);


        const auth = firebase.auth();

        if(!auth || !auth.currentUser)
        {
            console.log("Not Logged in")
            return
        }


        auth.currentUser.getIdToken().then(function(idToken) {
            axios.post(baseAPIURL+'profile',{},{"headers":{"Authorization": idToken}}).then(response => {
                console.log(response)

                try 
                {
                    //const jsonData = response.json();
                    if(!response.error)
                        dispatch({ type: 'PROFILE_LOADED',Profile:response });
                    else
                        dispatch({ type: 'PROFILE_ERROR'});

                }
                catch (error) 
                {
                    console.log(error)
                    dispatch({ type: 'PROFILE_ERROR'});

                }
                
            }).catch((error)=>{
                console.log(error)
                dispatch({ type: 'PROFILE_ERROR'});
            });
        });
}

import React, {Component,useState  } from 'react'
import { NavLink,Link,useOutletContext } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';

import { Routes, Route, useParams } from 'react-router-dom';

import { connect } from 'react-redux'
import { firestoreConnect } from  'react-redux-firebase'
import { compose } from 'redux'
import firebase from 'firebase/compat/app'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CardContent from '@mui/material/CardContent';
 
import {baseAPIURL} from "../../store/api/Access"

import  { useEffect  } from 'react';
import  axios  from 'axios'

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import * as Icons from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';


import Page404 from '../Page404';
import Loader from "../../components/other/Loader"
import { Divider,IconButton,Dialog,DialogContent , FormControlLabel, Switch, FormControl,InputLabel,Select,MenuItem } from '@mui/material';
import List from '../Components/List';



const Index = ({user,}) => {

  const Params = useParams();
  const componentDetailstest = useOutletContext();

    
  const [name, setName] = React.useState(componentDetailstest.name);

  const [advancedSettings, setAdvancedSettings] = React.useState(false);

  const [formError, setFormError] = React.useState(false);
  
  const [details, setDetails] = React.useState(componentDetailstest.config);
  const [lists, setLists] = React.useState(componentDetailstest.config.lists);
  const [config, setConfig] = React.useState(componentDetailstest.config.config);

  const [{loading,saving,error}, setStatus] = React.useState({loading:true});
  
    const navigate = useNavigate();
    

    console.log("componentDetailstest")
    console.log(componentDetailstest)

  
  
    const handleAdd = () => {
      
      
      /*
      //setFormError(true);
      
      setStatus({saving:true})

      firebase.auth().currentUser.getIdToken().then(function(idToken) {

        let data = JSON.stringify({
          "name":name,
          "group":group,
          "config":{
            details:details,
            lists:lists
          }
        });

        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: baseAPIURL+'components/add',
          headers: { 
            'Authorization': idToken, 
            'Content-Type': 'application/json'
          },
          data : data
        };
        
        axios.request(config).then(Result => {
                                
            console.log(Result)
            if(Result.data.error)
            {
                setStatus({error:true});
            }
            else if(Result.data.success)
            {
                setStatus({});

                if(Result.data.id)
                {
                  navigate("/components/"+Result.data.id)
                }
            }
            else
            {
                setDetails(null)
                setStatus({error:true});
            }
          
      }).catch(err => {
            console.error("handleSubmit")
            console.error(err)
            setStatus({error:true});
      });
    });
*/
    }


    
    useEffect(() => {
      //LoadData();
    }, []);






    const handleListDelete = (index) =>{
      
      if(!window.confirm("Are you sure?"))
        return;

      if(lists.length<=index)
        return;

      var TempList = [...lists]
      TempList.splice(index, 1);
      setLists(TempList)
    }
    

    const handleListAdd = () =>{
      var TempList = [...lists]
      TempList.push({});
      setLists(TempList)
    }

    const handleListName = (index, Value) =>{
      
      if(lists.length<=index)
        return;

      var TempList = [...lists]
      TempList[index].name = Value
      setLists(TempList)
    }

    const handleListType = (index, Value) =>{
      
      if(lists.length<=index)
        return;

      var TempList = [...lists]
      TempList[index].type = Value
      setLists(TempList)
    }

    



    return (
                <Box >

                  <h2>Component Settings </h2>
                  <Box>
                    
                  <Box>
                    <TextField error={(formError && name=="")} value={name} onChange={(e)=>setName(e.target.value)} helperText={formError && name=="" ? "Cannot be blank.":""} id="fName" label="Component Name" fullWidth variant="outlined" />
                    <br/><br/>
                  </Box>



                    <Box sx={{mt:2, p:1, pt:2, pb:2,border:"#02af48ff 1px solid", borderRadius:"4px"}}>

                      <Button onClick={()=>setAdvancedSettings(!advancedSettings)} sx={{m:0,p:0,color:"#02af48ff"}}>
                        {advancedSettings?<ArrowDropUpIcon/>:<ArrowDropDownIcon/>  } Advanced Settings
                      </Button>

                      {advancedSettings ?
                        <Box  sx={{borderTop:"#02af48ff 1px solid",mt:2,pt:1}}>
                          
                          
                          <FormControlLabel control={<Switch defaultChecked />} label="Globle Component" />


                          


                        <Divider/>

                        <h3>Lists</h3>
                        {lists &&   lists.map((row, index) => {
                            return (
                                <Box>

                                  <Box sx={{display:"flex"}}>
                                    <h4>List {(index+1)}</h4>

                                    <Box sx={{flexGrow:1}} />

                                    <IconButton onClick={()=>handleListDelete(index)} aria-label="delete"  size="small" sx={{color:"#02af48ff"}}>
                                      <DeleteIcon  fontSize="small" />
                                    </IconButton>

                                  </Box>

                                  <TextField 
                                    error={(formError && row.name=="")} 
                                    value={row.name} 
                                    onChange={(e)=>handleListName(index,e.target.value)} 
                                    helperText={formError && row.name=="" ? "Cannot be blank.":""} 
                                    label="List Name" 
                                    fullWidth 
                                    variant="outlined" />
                                  <br/><br/>

                                  <FormControl fullWidth>
                                    <InputLabel>Type</InputLabel>
                                    <Select
                                      value={row.type?row.type:"list"}
                                      //label="Age"
                                      onChange={(e)=>handleListType(index,e.target.value)}
                                    >
                                      <MenuItem value={"list"}>List</MenuItem>
                                      <MenuItem value={"view"}>View</MenuItem>
                                      <MenuItem value={"files"}>Files</MenuItem>
                                      <MenuItem value={"Schedule"}>schedule</MenuItem>
                                    </Select>
                                  </FormControl>

                                  
                                </Box>
                            )}
                        )}
                        <Box  sx={{textAlign:"center"}} >
                          <Button onClick={handleListAdd}>Add List</Button>
                        </Box>

                        </Box>
                        :
                        null
                      }
                    </Box>
                    
                  </Box>

                  <Box sx={{mt:3, textAlign:"right"}}>
                    <Button onClick={handleAdd} variant="contained"  sx={{textAlign:"center"}} >
                      Add
                    </Button>
                  </Box>









            <Dialog
              open={saving}
            >
              <DialogContent>
                Saving
              </DialogContent>
            </Dialog>

            </Box>
    );
}



const mapStateToProps = (state) => {
    return {
        user:firebase.auth().currentUser,
    }
}

export default compose(
    connect(mapStateToProps)
)(Index);
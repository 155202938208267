import React from 'react';

import { BrowserRouter, Route, Routes } from 'react-router-dom'

import Header from './components/header/Header'
import Footer from './components/footer/Footer'
import Page404 from './views/Page404' 

import Home from './views/Home'
import Logout from './views/auth/Logout'


import Schedules from './views/Schedules/List'
import SchedulesDetals from './views/Schedules/Details'
import SchedulesAdd from './views/Schedules/Add'


import AddComponemt from './views/Add/AddComponemt'
import AddGroup from './views/Add/AddGroup'
import Add from './views/Add/Add'
import AddComponemtSettings from './views/Add/AddComponemtSettings'

import Files from './views/Files/List'
import FilesDetails from './views/Files/Details'
import FilesUpload from './views/Files/Upload'

import ComponentsList from './views/Components/List'
import ComponentsHeader from './views/Components/Header'
import ComponentsDetails from './views/Components/Details'
import ComponentsSettings from './views/Components/Settings'
//import ComponentsItem from './views/Components/Item'
//import ComponentsFiles from './views/Components/types/Files'
//import ComponentsSchedules from './views/Components/Schedules'
//import Filesupload from './views/Components/Filesupload'

import SettingsProfile from './views/Settings/SettingsProfile'
import SettingsNotifications from './views/Settings/SettingsNotifications'
import SettingsPermissions from './views/Settings/SettingsPermissions'
import SettingsComponents from './views/Settings/SettingsComponents'
import SettingsGroups from './views/Settings/SettingsGroups'


import Notifications from './views/Notifications'


import CardMenu from "./components/components/CardMenu"


function App() {
  return (
    <BrowserRouter>
      <div className="App">
          <Header />

          <div className="MainView">
            <Routes >
              <Route path='/' element={<Home/>} />

              <Route path='/add' element={<Add/>} />
              <Route path='/add/component' element={<AddComponemt/>} />  {/** TO DO */}
              <Route path='/add/group' element={<AddGroup/>} />  {/** TO DO */}
              <Route path='/add/component/custom' element={<AddComponemtSettings/>} />
              <Route path='/add/component/:templateId' element={<AddComponemtSettings/>} />

              <Route path='/schedules' element={<Schedules/>} />  {/** TO DO */}
              {/*<Route path='/schedules/add' element={<SchedulesAdd/>} />
              <Route path='/schedules/details' element={<Page404/>} />
              <Route path='/schedules/details/:id' element={<SchedulesDetals/>} />*/}

              <Route path='/files' element={<Files/>} />
              <Route path='/files/upload' element={<FilesUpload/>} />
              {/*<Route path='/files/details' element={<Page404/>} />
              <Route path='/files/details/:id' element={<FilesDetails/>} />*/}

              <Route path='/logout' element={<Logout/>} />

              <Route path='/settings' element={<SettingsProfile/>} />
              <Route path='/settings/notifications' element={<SettingsNotifications/>} />
              <Route path='/settings/permissions' element={<SettingsPermissions/>} />
              <Route path='/settings/components' element={<SettingsComponents/>} />
              <Route path='/settings/groups' element={<SettingsGroups/>} />

              <Route path='/notifications' element={<Notifications/>} />
              
              <Route path='/components' element={<ComponentsList/>} />

              <Route path='/components/:componentId'  element={<ComponentsHeader />}> 

                <Route index element={<ComponentsDetails />} /> {/** TO DO */}
                <Route path='settings' element={<ComponentsSettings/>} />  {/** TO DO */}
                <Route path=':viewId' element={<ComponentsDetails/>} />  {/** TO DO */}
                <Route path=':viewId/:itemId' element={<ComponentsDetails/>} />  {/** TO DO */}

                {/*<Route path='schedule' element={<ComponentsDetails/>}/>  {/** TO DO * /}
                <Route path='files' element={<ComponentsDetails/>} />  {/** TO DO * /}
                <Route path='files/upload' element={<ComponentsDetails/>} />  {/** TO DO * /}

                <Route path=':itemId'> 

                  <Route index element={<ComponentsDetails/>} />  {/** TO DO * /}
                  <Route path='files' element={<ComponentsDetails/>} />  {/** TO DO * /}
                  <Route path='files/upload' element={<ComponentsDetails/>} />  {/** TO DO * /}

                </Route>*/}

              </Route>


              



              <Route path='/group' element={<Home/>} />  {/** TO DO */}
              <Route path='/group/:groupId' element={<ComponentsList/>} />  {/** TO DO */}


              <Route path='*'  element={<Page404/>}  status={404}/>

            </Routes>
          </div>


          <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;

import React, {Component,useState  } from 'react'
import { Link,Outlet,useOutletContext } from 'react-router-dom'
import { connect } from 'react-redux'
import { firestoreConnect } from  'react-redux-firebase'
import { compose } from 'redux'


import {Box, Drawer,Typography, List, ListItem, ListItemText,useTheme,useMediaQuery} from '@mui/material';

import IconButton from '@mui/material/IconButton';
import PersonIcon from '@mui/icons-material/Person';
import AppsIcon from '@mui/icons-material/Apps';

import {  NavLink } from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu';
import { Routes, Route, useParams } from 'react-router-dom';

//import { useNavigate  } from 'react-router-dom'
import {baseAPIURL,downloadAPIURL} from "../../store/api/Access"

import  { useEffect  } from 'react';
import  axios  from 'axios'

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

import Page404 from '../Page404';
import Loader from "../../components/other/Loader"



import Files from './Types/Files';
import None from './Types/None';
import Schedules from './Types/Schedules';
import ViewType from './Types/View';
import Table from './Types/Table';


  const View = (props) => {
    const Params = useParams();
    const componentDetails = useOutletContext();

    const [list, setList] = React.useState(null);
    const [Details, setComponentDetails] = React.useState(null);
    const [{loading,loadingMore,error}, setStatus] = React.useState({loading:true});
  
    const [open, setOpen] = useState(false);
    const theme = useTheme();



      const LoadData = () => {
          /*  setStatus({loading:true});

          //setList(null)
          console.log("LoadData")
  
          console.log(firebase.auth().currentUser)
          firebase.auth().currentUser.getIdToken().then(function(idToken) {

            let data = JSON.stringify({
              "id": Params.componentId
            });

            let config = {
              method: 'post',
              maxBodyLength: Infinity,
              url: baseAPIURL+'components/get',
              headers: { 
                'Authorization': idToken, 
                'Content-Type': 'application/json'
              },
              data : data
            };
            
            axios.request(config).then(Result => {
                                    
                console.log(Result)
                if(Result.data.error)
                {
                    setStatus({error:true});
                }
                else if(Result.data)
                {
                    setComponentDetails(Result.data)
                    setStatus({});
                    
                    if(Result.data.config && Result.data.config.lists)
                    {
                      setCompList(Result.data.config.lists)
                    }
                    
                    if(Result.data.config && Result.data.config.details)
                    {
                      setCompConfig(Result.data.config.details)
                    }

                }
                else
                {
                  setComponentDetails(null)
                  setStatus({error:true});
                }
              
          }).catch(err => {
                console.error("handleSubmit")
                console.error(err)
                setStatus({error:true});
          });
        });
        */
          
      };
  
    
    useEffect(() => {
      
      console.log("page change")
      LoadData();
    }, []);


    if(error)
      return(<Page404/>)

    if(loading)
      return(<Loader/>)



      var view = null

      if(componentDetails && componentDetails.Views && componentDetails.Views.length>0)
      { 
        view = componentDetails.Views[0];

        if(("viewId" in Params))
        {
          componentDetails.Views.forEach(element => {
            if("name" in element && element.name == Params.viewId)
            {
              view = element;
            }
          });
        }
      }


      var ViewType =  None;
      
      if(view && "type" in view)
      {
        switch (view.type) {
          case "view":
            ViewType = ViewType;
            break;
            
          case "list":
            case "table":
              ViewType = Table;
          break;
          
          case "files":
            ViewType = Files;
            break;
          
            case "schedules":
              ViewType = Schedules;
              break;
        }
      }


      console.log(view)
      console.log("view")


    return <ViewType viewDetails={view} componentDetails={componentDetails} list={list} Details={Details} />;
 };


export default (View);
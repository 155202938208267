import React, {Component,useState  } from 'react'
import { NavLink,Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';

import { connect } from 'react-redux'
import { firestoreConnect } from  'react-redux-firebase'
import { compose } from 'redux'
import firebase from 'firebase/compat/app'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';

import {baseAPIURL} from "../../store/api/Access"

import  { useEffect  } from 'react';
import  axios  from 'axios'


import * as Icons from '@mui/icons-material';

import Page404 from '../Page404';
import Loader from "../../components/other/Loader"



const Index = ({user,}) => {

    
    const [templte, setTemplte] = React.useState(null);

    const [limit, setLimit] = React.useState(30);
    const [list, setList] = React.useState(null);
    const [{loading,loadingMore,error}, setStatus] = React.useState({loading:true});
  
    const navigate = useNavigate();
    



      const LoadData = () => {
          setStatus({loading:true});
          //setList(null)
          console.log("LoadData")
  
          console.log(firebase.auth().currentUser)
          firebase.auth().currentUser.getIdToken().then(function(idToken) {

            let data = JSON.stringify({
              "limit": limit
            });

            let config = {
              method: 'post',
              maxBodyLength: Infinity,
              url: baseAPIURL+'tempates/list',
              headers: { 
                'Authorization': idToken, 
                'Content-Type': 'application/json'
              },
              data : data
            };
            
            axios.request(config).then(Result => {
                                    
                console.log(Result)
                if(Result.data.error)
                {
                    setStatus({error:true});
                }
                else if(Result.data.list)
                {
                    setList(Result.data.list)
                    setStatus({});
                }
                else
                {
                    setList([])
                    setStatus({error:true});
                }
              
          }).catch(err => {
                console.error("handleSubmit")
                console.error(err)
                setStatus({error:true});
          });
        });
          
      };
  
  
  
      const handleAdd = () => {
        if(templte == null)
        {
            alert("please select one.")
            return;
        }
        navigate("/add/component/"+templte);
      }


    
    useEffect(() => {
      LoadData();
    }, []);

    




    if(error)
      return(<Page404/>)

    if(loading)
      return(<Loader/>)





        return (
            <main style={{margin:"30px"}}>
                <Card>
                    <CardContent >
                        <h2> Select Componemt</h2>
                        <p> Select the componemt you would like to add</p>
                        <Box>
                        <Grid container spacing={2}>

                            {list && list.map((row, index) => {

                                console.log(row);
                                const Icon = row.icon in Icons ? Icons[row.icon]:null
                                
                                return(
                                    <Grid item sm={4} lg={2} md={3} xs={12} >
                                        <Button variant={templte && templte==row.id?"outlined":"Text"} onClick={()=>setTemplte(row.id)}sx={{textAlign:"center",width:"100%",display: "block;"}} >
                                            <Box>
                                                {row.icon in Icons ? <Icon  sx={{ fontSize: 40, color:"#02af48ff"}}/> : null}
                                            </Box>
                                            <Box>
                                                <h2 style={{margin:0, }}>{row.name}</h2>
                                            </Box>
                                        </Button>
                                    </Grid>)
                            })}

                                    <Grid item sm={4} lg={2} md={3} xs={12} >
                                        <Button variant={templte && templte=="custom"?"outlined":"Text"} onClick={()=>setTemplte("custom")} sx={{textAlign:"center",width:"100%",display: "block;"}} >
                                            {/*<Box>
                                                <Icon  sx={{ fontSize: 40, color:"#02af48ff"}}/>
                        </Box>*/}
                                            <Box>
                                                <h2 style={{margin:0, }}>Custom</h2>
                                            </Box>
                                        </Button>
                                    </Grid>
                                
                        </Grid>
                        </Box>

                        <Box sx={{mt:3, textAlign:"right"}}>
                            <Button onClick={handleAdd} variant="contained"  sx={{textAlign:"center"}} >
                                    Add
                            </Button>
                        </Box>
                    </CardContent>
                </Card>
            </main>
        );
}



const mapStateToProps = (state) => {
    return {
        user:firebase.auth().currentUser,
    }
}

export default compose(
    connect(mapStateToProps)
)(Index);
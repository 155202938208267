import React, {Component,useState  } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { firestoreConnect } from  'react-redux-firebase'
import { compose } from 'redux'


import {
    Button,
    Box,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    TextField, 
    FormControl,
  Select,
  MenuItem
    
  } from '@mui/material';

  



import { Routes, Route, useParams } from 'react-router-dom';


import  { useEffect  } from 'react';

import 'firebase/compat/auth';


  const View = (props) => {
    const {user} = props
    const Params = useParams();
    

      const LoadData = () => {
          
          
      };
  
    
    useEffect(() => {
      LoadData();
    }, []);



    return (
      <main >
        <Box sx={{textAlign:"center"}}>
            <h3>There is none here.</h3>
            <p>Please check your settings</p>
        </Box>
    
        
      </main>
    );
  };


export default (View);
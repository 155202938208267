import React from 'react'
import { getFirebase } from 'react-redux-firebase';
import Loader from "../../components/other/Loader"


const Logout = (props) => {

    const firebase = getFirebase()
    firebase.auth().signOut().then(()=>{
        
        window.location.href = "/";
    })
    return <Loader />
}
export default (Logout)
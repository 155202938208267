import React, {Children, Component,useState  } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import { firestoreConnect } from  'react-redux-firebase'
import { compose } from 'redux'


import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    TextField, 
    FormControl,
  Select,
  MenuItem,
  Box, Drawer,Typography, List, ListItem, ListItemText,useTheme,useMediaQuery
    
  } from '@mui/material';

  import IconButton from '@mui/material/IconButton';
  import PersonIcon from '@mui/icons-material/Person';
  import AppsIcon from '@mui/icons-material/Apps';

  import { Routes, Route, useParams } from 'react-router-dom';



  import * as Icon from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';

  
  const View = ({children,subheader,title}) => {

    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleDrawerToggle = () => {
      setOpen(!open);
    };

    const Drawer = () => {
      
    const props = useParams();
      return(
        <div>
          <List>

              <ListItem component={NavLink} to={"/components/"+props.componentId} button key={"Overview"}>
                <ListItemText primary={"Overview"} />
              </ListItem>
              
              <Divider/>

              <ListItem component={NavLink} to={"/components/"+props.componentId+"/schedule"} button key={"Schedule"}>
                <ListItemText primary={"Schedule"} />
              </ListItem>

              <ListItem component={NavLink} to={"/components/"+props.componentId+"/files"} button key={"Files"}>
                <ListItemText primary={"Files"} />
              </ListItem>
               
              <ListItem component={NavLink} to={"/components/"+props.componentId+"/settings"} button key={"Settings"}>
                <ListItemText primary={"Settings"} />
              </ListItem>
              
              
            
          </List>
        </div>
      )
    };




    return (
<Card sx={{m:2,}}>


    <Box sx={{ borderBottom:"1px solid #111",display: 'flex',pt:2, pb:1,pr:2,pl:2 }}>
      
    {isMobile && (
        <Button onClick={handleDrawerToggle}>
          <MenuIcon sx={{color: "#02af48ff"}} />
        </Button>
      )}


      <Box sx={{flexGrow:1}}>
        {title && 
            <Typography variant="h5" component="div">
              {title}
            </Typography>
        }
        {subheader && 
            <Typography variant="body" component="div">
              {subheader}
            </Typography>
        }
      </Box>
    </Box>


    <Box sx={{ display: 'flex'}}>

  <Box sx={{borderRight:(!isMobile ? "1px solid #111" : null)}}>
    
    {(!isMobile) && (
      <Box sx={{ width: '240px', transition: theme.transitions.create(['width'], { easing: theme.transitions.easing.sharp, duration: theme.transitions.duration.enteringScreen }) }}>
          <Drawer/>
      </Box>
    )}
  </Box>


    <CardContent  sx={{flexGrow:1}}>
      
          

      {(open && isMobile) && (
        <Box sx={{ width: '100%', transition: theme.transitions.create(['width'], { easing: theme.transitions.easing.sharp, duration: theme.transitions.duration.enteringScreen }) }}>
          <Drawer/>
        </Box>
      )}

          
      <Box sx={{flexGrow:1, mt:3}}>
          
        {children}

      </Box>
    </CardContent>

  </Box>

</Card>


    );
  };


export default (View);
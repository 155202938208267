import React, {Component,useState  } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'

import { NavLink  } from 'react-router-dom'
  

import TimeAgo from 'react-timeago'
//import Loader from '../components/other/Loader'


import HomeIcon from '@mui/icons-material/Home';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ChecklistIcon from '@mui/icons-material/Checklist';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PersonIcon from '@mui/icons-material/Person';
import InfoIcon from '@mui/icons-material/Info';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import {TableHead,Badge} from '@mui/material';
import TableRow from '@mui/material/TableRow';

import {Container,Paper,Card,CardContent} from '@mui/material/';
import Box from '@mui/material/Box';


import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

import Page404 from './Page404';
import Loader from "../components/other/Loader"

//import { useNavigate  } from 'react-router-dom'
import {baseAPIURL} from "../store/api/Access"

import  { useEffect  } from 'react';
import  axios  from 'axios'



 
const NotificationsView = (props) => {
      
    
    const [limit, setLimit] = React.useState(30);
    const [list, setList] = React.useState(null);
    const [{loading,loadingMore,error}, setStatus] = React.useState({loading:true});
  
    //const navigate = useNavigate();
    

    const HandleLoadMore = () => {

        const NewLimit = limit+30;
        setLimit(NewLimit)
        setStatus({loadingMore:true})
        firebase.auth().currentUser.getIdToken().then(function(idToken) {
  
  
            let data = JSON.stringify({
              "limit": NewLimit
            });
  
            let config = {
              method: 'post',
              //maxBodyLength: Infinity,
              url: baseAPIURL+'notifications/list',
              headers: { 
                'Authorization': idToken, 
                //'Content-Type': 'application/json'
              },
              data : data
            };
            
            axios.request(config).then(Result => {
                                    
                console.log(Result)
                if(Result.data.error)
                {
                    setStatus({error:true});
                }
                else if(Result.data.notifications)
                {
                    setList(Result.data.notifications)
                    setStatus({});
                }
                else
                {
                    setList([])
                    setStatus({error:true});
                }
              
          }).catch(err => {
                console.error("handleSubmit")
                console.error(err)
                setStatus({error:true});
          });
        });
  
  
  
      }





      
    const ClearAll = () => {

        const NewLimit = limit+30;
        setLimit(NewLimit)
        setStatus({loadingMore:true})
        firebase.auth().currentUser.getIdToken().then(function(idToken) {
  
  
            let data = JSON.stringify({
              "limit": NewLimit
            });
  
            let config = {
              method: 'post',
              //maxBodyLength: Infinity,
              url: baseAPIURL+'notifications/clear',
              headers: { 
                'Authorization': idToken, 
                //'Content-Type': 'application/json'
              },
              data : data
            };
            
            axios.request(config).then(Result => {
                                    
                console.log(Result)
          }).catch(err => {
                console.error("handleSubmit")
                console.error(err)
                setStatus({error:true});
          });
        });
  
  
  
      }



      const LoadData = () => {
          setStatus({loading:true});
          //setList(null)
          console.log("LoadData")
  
          console.log(firebase.auth().currentUser)
          firebase.auth().currentUser.getIdToken().then(function(idToken) {

            let data = JSON.stringify({
              "limit": limit
            });

            let config = {
              method: 'post',
              maxBodyLength: Infinity,
              url: baseAPIURL+'notifications/list',
              headers: { 
                'Authorization': idToken, 
                'Content-Type': 'application/json'
              },
              data : data
            };
            
            axios.request(config).then(Result => {
                                    
                console.log(Result)
                if(Result.data.error)
                {
                    setStatus({error:true});
                }
                else if(Result.data.notifications)
                {
                    setList(Result.data.notifications)
                    setStatus({});
                    ClearAll()
                }
                else
                {
                    setList([])
                    setStatus({error:true});
                }
              
          }).catch(err => {
                console.error("handleSubmit")
                console.error(err)
                setStatus({error:true});
          });
        });
          
      };
  
  
  


    
    useEffect(() => {
      LoadData();
    }, []);

    



    if(error)
      return(<Page404/>)

    if(loading)
      return(<Loader/>)



    const row = {type:"Properties"}
    
    return(
        <main style={{margin:"30px"}}>
            <Card>


                

            <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                        {/*<TableHead>
                            <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell align="right">Size</TableCell>
                            <TableCell align="right">Date</TableCell>
                            </TableRow>
    </TableHead>*/}
                        <TableBody>
                            



{list && list.map((item, index) => {

return(
                                <TableRow 
                                component={NavLink}
                                to="/notifications" 
                                    hover 
                                    key={item.id} 
                                >
                                    <TableCell className='notificationsTableIcon'>
                                        <Badge  variant={!item.read && "dot"} color="error">
                                            {item.type=="Infomation" && <InfoIcon/>}
                                            {item.type=="Properties" && <HomeIcon/>}
                                            {item.type=="Buget" && <AccountBalanceIcon/>}
                                            {item.type=="Files" && <AttachFileIcon/>}
                                            {item.type=="Lists" && <ChecklistIcon/>}
                                            {item.type=="Schedules" && <CalendarMonthIcon/>}
                                            {item.type=="Profile" && <PersonIcon/>}
                                        </Badge>
                                        
                                    </TableCell>
                                    <TableCell>{item.message}</TableCell>
                                    <TableCell align="right"><TimeAgo date={item.date}/></TableCell>
                                </TableRow>)
})}





                        </TableBody>
                        </Table>
                    </TableContainer>



            </Card>
        </main>
    )

}



export default (NotificationsView);
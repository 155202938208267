import React, {Component } from 'react'



const Loader = (props) => {


    return(
        <div class={props && props.color == "green" ? "loading-container-green" :  "loading-container"}>
          <div class="loading-dots">
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
          </div>
          <div class="loading-text">Loading</div>
        </div>
    )

}

export default (Loader);
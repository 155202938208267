import React from 'react'

import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';


const View = () => {

    return (
        <Container>
        <Card  style={{textAlign:"center", marginTop:"30px"}}>
        <CardContent sx={{textAlign:"center", }}>

            <SentimentDissatisfiedIcon  sx={{ fontSize: 50, color:"#02af48ff"}}/>
                <h3><strong>404.</strong> Could not find page.</h3>
            
            </CardContent>
                            </Card>
                            </Container>
        )
    
}
export default View

import React, {Component,useState,useCallback} from 'react'
import { NavLink,Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { firestoreConnect } from  'react-redux-firebase'
import { compose } from 'redux'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';


//import { useNavigate  } from 'react-router-dom'
import {baseAPIURL,uploadAPIURL} from "../../store/api/Access"

import  { useEffect  } from 'react';
import  axios  from 'axios'

import {useDropzone} from 'react-dropzone'

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

import Page404 from '../Page404';



var uploadFilesArray = [];

const Index = ({user,}) => {

    
  
    const [{loading,error}, setStatus] = React.useState({});
    const [update, setUpload] = React.useState(0);
    
    //const history = useHistory()
  
    const onDrop = useCallback(async (acceptedFiles) => {


        
        await  firebase.auth().currentUser.getIdToken().then( async function(idToken) {

            await acceptedFiles.forEach((file) => {
        
            const ItemLocation = uploadFilesArray.length
            file.id = ItemLocation
            uploadFilesArray.push({name:file.name, state:"Pending"})
            setUpload(Math.random())
            console.log(uploadFilesArray)
                

            const reader = new FileReader()

            reader.onabort = () => {
                console.log('file reading was aborted')
                uploadFilesArray[file.id].state = "Error";
                setUpload(Math.random())
            }


            reader.onerror = () => {
                console.log('file reading has failed')
                uploadFilesArray[file.id].state = "Error";
                setUpload(Math.random())
            }


            reader.onload = () => {
            
                
                console.log(file.id)
                console.log(uploadFilesArray)
                uploadFilesArray[file.id].state = "Loading";
                setUpload(Math.random())

                
                // Do whatever you want with the file contents
                console.log(typeof reader.result )
                console.log( reader.result )
                var binaryStr =  reader.result

                console.log(file.type)
                console.log(binaryStr)
                
                const header = {"headers":{"Content-Type": file.type?file.type:'text/plain',"Authorization": idToken,"File-Name": file.name}}
                //const header = {"headers":{"X-API-Key": match.params.setId}}
                console.log(uploadAPIURL)
                //setUploadCount(uploadCount+1)
                

                axios.post(uploadAPIURL,binaryStr,header).then(Result => {
                    
                    //setUploadCount(uploadCount-1)                       
                    console.log(Result)
                    if(Result.data.error)
                    {
                        uploadFilesArray[file.id].state = "Error";
                        setUpload(Math.random())
                        //setStatus({error:true});
                    }
                    else if(Result.data.id)
                    {
                        uploadFilesArray[file.id].state = "Uploaded";
                        setUpload(Math.random())
                        //setStatus({});
                    }
                    else
                    {
                        uploadFilesArray[file.id].state = "Error";
                        setUpload(Math.random())
                        //setStatus({error:true});
                    }
                    
                }).catch(err => {
                    //setUploadCount(uploadCount-1)   
                    console.error("handleSubmit")
                    console.error(err)
                    //setStatus({error:true});
                    uploadFilesArray[file.id].state = "Error";
                    setUpload(Math.random())
                    
                });

            }


            reader.readAsArrayBuffer(file)
            setUpload(Math.random())
            //reader.readAsBinaryString(file)
            
        })
        setUpload(Math.random())
    })
  
      
    }, [])
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

    

    
    useEffect(() => {
        uploadFilesArray = [];
        setUpload(Math.random())
    }, []);

    


    
    if(!user || error )
      return(<Page404/>)


    return (
        <main style={{margin:"30px"}}>
            <Card sx={{p:2}}>
                <h3>Upload image or file</h3>
                    <Box align="center" sx={{m:0,mt:2,mb:2,p:1,pt:"30px",pb:"30px", border: "1px solid #444", with:"100%", textAlign:"center"}} {...getRootProps()}>
                        <input {...getInputProps()} />
                        {
                            isDragActive ? <p>Drop files here<br/> 10MB max</p> :<p>Drop files here or click here to select files<br/> 10MB max</p> 
                        }
                    </Box>

                    <Box>
                        
                        {uploadFilesArray && uploadFilesArray.map((row, index) => {
                            return(<Box sx={{display:"flex"}}>
                                    <Box sx={{maxWidth:"100%",whiteSpace: "nowrap",overflow: "hidden",textOverflow: "ellipsis;"}}>
                                        {row.name}
                                    </Box>
                                    <Box sx={{ml:1,flexGrow:1, textAlign:"right",fontWeight:"600", color:"#02af48ff"}}>
                                        {row.state}
                                    </Box>
                                </Box>)
                        })}
                    </Box>
              

            </Card>        
        </main>
    );
}



const mapStateToProps = (state) => {
    return {
        user:firebase.auth().currentUser,
    }
}

export default compose(
    connect(mapStateToProps)
)(Index);
const initState = {}

const profileReducer = (state = initState, action) => {


    switch(action.type) {
        case "PROFILE_LOADED": 
        
            return {
                ...state,
                isLoaded: true,
                email:action.Profile.email,
                uuid:action.Profile.uuid,
                name:action.Profile.name,
            }
        case "PROFILE_LOGOUT": 
            return {}
        case "PROFILE_ERROR":
            console.error("PROFILE_ERROR")
            console.error(action.error)
            return {
                ...state,
                error: true,
            }
        case "PROFILE_NOTIFICATIONS":
            return {
                ...state,
                notifications: action.notifications,
            }
        case "PROFILE_NOTIFICATIONSCOUNT":
            return {
                ...state,
                notificationsCount: action.count,
            }
        /*case "PROFILE_FOUND":
            return {
                ...state,
                found: true,
            }
        case "PROFILE_NOTFOUND":
            return {
                ...state,
                found: false,
            }*/
        default: 
            return state;
    }

    return state;
}

export default profileReducer;
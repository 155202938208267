import React, {Component,useState  } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { firestoreConnect } from  'react-redux-firebase'
import { compose } from 'redux'


import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    TextField, 
    FormControl,
  Select,
  MenuItem
    
  } from '@mui/material';

  
import CardMenu from "../../components/settings/CardMenu"


  const AccountProfileDetails = (props) => {
    const {user} = props
    const [values, setValues] = useState({notifications:{}});



    return (
        <main >
      <form /*onSubmit={handleSubmit}*/ action="post"
        autoComplete="off"
        noValidate
      >

      <CardMenu 
            //subheader="The information can be edited"
            title="Permissions"
      >
        
            <Button
              type="submit"
              color="primary"
              variant="contained"
            >
              Save details
            </Button>
            
        </CardMenu>
        
    </form>
      </main>
    );
  };

  const mapStateToProps = (state) => {

    return {
        //user:firebase.auth().currentUser,
    }
}


export default compose(
    connect(mapStateToProps),
    /*firestoreConnect(props =>{
        return[
            { collection: 'Users', storeAs: 'Profile', doc:props.user.uid},
        ]
    })*/
) (AccountProfileDetails);
import React, {Component,useState  } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { firestoreConnect } from  'react-redux-firebase'
import { compose } from 'redux'


import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    TextField, 
    FormControl,
  Select,
  MenuItem
    
  } from '@mui/material';

  import CardMenu from "../../components/settings/CardMenu"
  
  const AccountProfileDetails = (props) => {
    const {user} = props
    const [values, setValues] = useState({notifications:{}});



    return (
        <main >
      <form /*onSubmit={handleSubmit}*/ action="post"
        autoComplete="off"
        noValidate
      >
        
        <CardMenu 
     subheader="Manage the notifications"
     title="Notifications"
        >

     <Grid
       container
       spacing={6}
       wrap="wrap"
     >


<Grid item md={6} sm={6}  xs={12} >
          <FormControl fullWidth>
            Devices: 
          </FormControl>
        </Grid>
        <Grid item md={6} sm={6} xs={12} >
          <FormControl fullWidth>
            <Select value={values.notifications.device} /*onChange={handleNotificationsChange}*/ name="device" inputProps={{ 'aria-label': 'Without label' }}>
              <MenuItem value={2}>All</MenuItem>
              <MenuItem value={1}>Selected</MenuItem>
              <MenuItem value={0}>None</MenuItem>
            </Select>
          </FormControl>
        </Grid>

<Grid item md={6} sm={6}  xs={12} >
  <FormControl fullWidth >
    Groups: 
  </FormControl>
</Grid>
<Grid item md={6} sm={6} xs={12} >
  <FormControl fullWidth >
    <Select value={values.notifications.groups} /*onChange={handleNotificationsChange}*/ name="groups"  inputProps={{ 'aria-label': 'Without label' }}>
      <MenuItem value={2}>All</MenuItem>
      <MenuItem value={1}>Selected</MenuItem>
      <MenuItem value={0}>None</MenuItem>
    </Select>
  </FormControl>
</Grid>


<Grid item md={6} sm={6}  xs={12} >
  <FormControl fullWidth >
    Device Actions: 
  </FormControl>
</Grid>
<Grid item md={6} sm={6} xs={12} >
  <FormControl fullWidth >
    <Select value={values.notifications.deviceActions} /*onChange={handleNotificationsChange}*/ name="deviceActions"  inputProps={{ 'aria-label': 'Without label' }}>
      <MenuItem value={2}>All</MenuItem>
      <MenuItem value={1}>Selected</MenuItem>
      <MenuItem value={0}>None</MenuItem>
    </Select>
  </FormControl>
</Grid>


<Grid item md={6} sm={6}  xs={12} >
  <FormControl fullWidth >
    Global Actions: 
  </FormControl>
</Grid>
<Grid item md={6} sm={6} xs={12} >
  <FormControl fullWidth >
    <Select value={values.notifications.globalActions} /*onChange={handleNotificationsChange}*/ name="globalActions"  inputProps={{ 'aria-label': 'Without label' }}>
      <MenuItem value={2}>All</MenuItem>
      <MenuItem value={1}>Selected</MenuItem>
      <MenuItem value={0}>None</MenuItem>
    </Select>
  </FormControl>
</Grid>


<Grid item md={6} sm={6}  xs={12} >
  <FormControl fullWidth >
    Device Notifications: 
  </FormControl>
</Grid>
<Grid item md={6} sm={6} xs={12} >
  <FormControl fullWidth >
    <Select value={values.notifications.deviceNotifications} /*onChange={handleNotificationsChange}*/ name="deviceNotifications"  inputProps={{ 'aria-label': 'Without label' }}>
      <MenuItem value={2}>All</MenuItem>
      <MenuItem value={0}>None</MenuItem>
    </Select>
  </FormControl>
</Grid>
       


<Grid item md={6} sm={6}  xs={12} >
  <FormControl fullWidth >
    Changes Settings: 
  </FormControl>
</Grid>
<Grid item md={6} sm={6} xs={12} >
  <FormControl fullWidth >
    <Select value={values.notifications.changesSettings} /*onChange={handleNotificationsChange}*/ name="changesSettings"  inputProps={{ 'aria-label': 'Without label' }}>
      <MenuItem value={2}>All</MenuItem>
      <MenuItem value={0}>None</MenuItem>
    </Select>
  </FormControl>
</Grid>
       












       <Grid
                item
                md={12}
                xs={12}
              >

              
            <Button
              color="primary"
              variant="contained"
              type="submit"
            >
              Save details
            </Button>
            </Grid>
     </Grid>
     
     </CardMenu>
    </form>
      </main>
    );
  };

  const mapStateToProps = (state) => {

    return {
        //user:firebase.auth().currentUser,
    }
}


export default compose(
    connect(mapStateToProps),
    /*firestoreConnect(props =>{
        return[
            { collection: 'Users', storeAs: 'Profile', doc:props.user.uid},
        ]
    })*/
) (AccountProfileDetails);
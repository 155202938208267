import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
import 'firebase/compat/analytics'
//import  Firebaseui  from 'firebaseui'
import { getAuth, connectAuthEmulator } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCyuczHV4QDzq0kGZWZjkRDf1Spa_Z5034",
  authDomain: "blizo-70335.firebaseapp.com",
  projectId: "blizo-70335",
  storageBucket: "blizo-70335.appspot.com",
  messagingSenderId: "434805039505",
  appId: "1:434805039505:web:3c0de572df9c22c5313a30",
  measurementId: "G-QBSY6RVW2B"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
//firebase.storage();



//const db = firebase.firestore();
const auth = firebase.auth;

/*
// eslint-disable-next-line no-restricted-globals
if (location.hostname === 'localhost') {
  const auth2 = getAuth();
  connectAuthEmulator(auth2,'http://localhost:9099/');//, { disableWarnings: true });
}*/

export default firebase;
export { /*db,*/ auth };
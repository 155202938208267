import React, {Component,useState  } from 'react'
import { Link,Outlet} from 'react-router-dom'
import { connect } from 'react-redux'
import { firestoreConnect } from  'react-redux-firebase'
import { compose } from 'redux'


import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    TextField, 
    FormControl,
  Select,
  MenuItem
    
  } from '@mui/material';

  

import {
Box, Drawer,Typography, List, ListItem, ListItemText,useTheme,useMediaQuery
  
} from '@mui/material';

import IconButton from '@mui/material/IconButton';
import PersonIcon from '@mui/icons-material/Person';
import AppsIcon from '@mui/icons-material/Apps';

import {  NavLink } from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu';



import { Routes, Route, useParams } from 'react-router-dom';

//import { useNavigate  } from 'react-router-dom'
import {baseAPIURL,downloadAPIURL} from "../../store/api/Access"

import  { useEffect  } from 'react';
import  axios  from 'axios'

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

import Page404 from '../Page404';
import Loader from "../../components/other/Loader"


  const AccountProfileDetails = (props) => {
    const {user} = props
    const Params = useParams();
    
    const [compConfig, setCompConfig] = React.useState({});
    //const [limit, setLimit] = React.useState(30);

    const [list, setList] = React.useState(null);
    const [componentDetails, setComponentDetails] = React.useState(null);
    const [{loading,loadingMore,error}, setStatus] = React.useState({loading:true});
  
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleDrawerToggle = () => {
      setOpen(!open);
    };
    //const navigate = useNavigate();
    

      const LoadData = () => {
            setStatus({loading:true});

          //setList(null)
          console.log("LoadData")
  
          console.log(firebase.auth().currentUser)
          firebase.auth().currentUser.getIdToken().then(function(idToken) {

            let data = JSON.stringify({
              "id": Params.componentId
            });

            let config = {
              method: 'post',
              maxBodyLength: Infinity,
              url: baseAPIURL+'components/get',
              headers: { 
                'Authorization': idToken, 
                'Content-Type': 'application/json'
              },
              data : data
            };
            
            axios.request(config).then(Result => {
                                    
                console.log(Result)
                if(Result.data.error)
                {
                    setStatus({error:true});
                }
                else if(Result.data)
                {
                    setComponentDetails(Result.data)
                    setStatus({});
                    
                    if(Result.data.config )
                    {
                      setCompConfig(Result.data.config)
                    }

                }
                else
                {
                  setComponentDetails(null)
                  setStatus({error:true});
                }
              
          }).catch(err => {
                console.error("handleSubmit")
                console.error(err)
                setStatus({error:true});
          });
        });
          
      };
  
    
    useEffect(() => {
      
      console.log("page change")
      LoadData();
    }, []);


    if(error)
      return(<Page404/>)

    if(loading)
      return(<Loader/>)








      
    const Drawer = () => {
      
      const props = useParams();
        return(
          <div>
            <List>
              
              <ListItem component={NavLink} to={"/components/"+props.componentId} button key={"Overview"}>
                  <ListItemText primary={"Overview"} />
                </ListItem>


              { compConfig &&  compConfig.Views && compConfig.Views.map((row,index)=>{
                if(row.type == "divider")
                  return(<Divider/>);

                return(
                  <ListItem component={NavLink} to={"/components/"+props.componentId+"/"+row.id} button key={row.name}>
                    <ListItemText primary={row.name} />
                  </ListItem>
              )})
              }


                  <ListItem component={NavLink} to={"/components/"+props.componentId+"/settings"} button key="Settings">
                    <ListItemText primary="Settings" />
                  </ListItem>

            </List>
          </div>
        )
      };

      



    return (
      <main >
    

        
        <Card sx={{m:2,}}>

        
          <Box sx={{ borderBottom:"1px solid #111",display: 'flex',pt:2, pb:1,pr:2,pl:2 }}>
            
            {isMobile && (
              <Button onClick={handleDrawerToggle}>
                <MenuIcon sx={{color: "#02af48ff"}} />
              </Button>
            )}


            <Box sx={{flexGrow:1}}>
                    <Typography variant="h5" component="div">
                      {componentDetails.name}
                    </Typography>
                
                {/*subheader && 
                    <Typography variant="body" component="div">
                      {subheader}
                    </Typography>
            */}
            </Box>
          </Box>


          <Box sx={{ display: 'flex'}}>

            <Box sx={{borderRight:(!isMobile ? "1px solid #111" : null)}}>

            {(!isMobile) && (
              <Box sx={{ width: '240px', transition: theme.transitions.create(['width'], { easing: theme.transitions.easing.sharp, duration: theme.transitions.duration.enteringScreen }) }}>
                  <Drawer/>
              </Box>
            )}
            </Box>


            <CardContent  sx={{flexGrow:1}}>
              
                  

              {(open && isMobile) && (
                <Box sx={{ width: '100%', borderBottom:"#aaa solid 1px", transition: theme.transitions.create(['width'], { easing: theme.transitions.easing.sharp, duration: theme.transitions.duration.enteringScreen }) }}>
                  <Drawer/>
                </Box>
              )}

                  
              <Box sx={{flexGrow:1, mt:3}}>
                  
                <Outlet context={componentDetails}/>
                


















              </Box>
            </CardContent>

          </Box>

        </Card>
        
      </main>
    );
  };

  const mapStateToProps = (state) => {

    return {
        //user:firebase.auth().currentUser,
    }
}


export default compose(
    connect(mapStateToProps),
    /*firestoreConnect(props =>{
        return[
            { collection: 'Users', storeAs: 'Profile', doc:props.user.uid},
        ]
    })*/
) (AccountProfileDetails);
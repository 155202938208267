import React, {Component,useState  } from 'react'
import { NavLink,Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { firestoreConnect } from  'react-redux-firebase'
import { compose } from 'redux'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import fileDownload from 'js-file-download';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import { Routes, Route, useParams } from 'react-router-dom';


//import { useNavigate  } from 'react-router-dom'
import {baseAPIURL,downloadAPIURL} from "../../store/api/Access"

import  { useEffect  } from 'react';
import  axios  from 'axios'

import Button from '@mui/material/Button';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DownloadIcon from '@mui/icons-material/Download';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

import Page404 from '../Page404';
import Loader from "../../components/other/Loader"


import * as Icon from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';


const Index = () => {
    const Params = useParams();
    const [limit, setLimit] = React.useState(30);
    const [list, setList] = React.useState(null);
    const [details, setDetails] = React.useState(null);
    const [{loading,loadingMore,error}, setStatus] = React.useState({loading:true});
  
    //const navigate = useNavigate();
    

    const HandleLoadMore = () => {

      const NewLimit = limit+30;
      setLimit(NewLimit)
      setStatus({loadingMore:true})
      firebase.auth().currentUser.getIdToken().then(function(idToken) {


          let data = JSON.stringify({
            "limit": NewLimit,
            "group": "groupId" in Params && Params.groupId ? Params.groupId : null
            
          });

          let config = {
            method: 'post',
            //maxBodyLength: Infinity,
            url: baseAPIURL+'components/list',
            headers: { 
              'Authorization': idToken, 
              'Content-Type': 'application/json'
            },
            data : data
          };
          
          axios.request(config).then(Result => {
                                  
              console.log(Result)
              if(Result.data.error)
              {
                  setStatus({error:true});
              }
              else if(Result.data.list)
              {
                  setList(Result.data.list)
                  setStatus({});
              }
              else
              {
                  setList([])
                  setStatus({error:true});
              }
            
        }).catch(err => {
              console.error("handleSubmit")
              console.error(err)
              setStatus({error:true});
        });
      });



    }



      const LoadData = () => {
          setStatus({loading:true});
          //setList(null)
          console.log("LoadData")
  
          console.log(firebase.auth().currentUser)
          firebase.auth().currentUser.getIdToken().then(function(idToken) {


            if("groupId" in Params && Params.groupId)
            {
                let dataGroup = JSON.stringify({
                  "id": Params.groupId,
                  
                });

                let configGroup = {
                  method: 'post',
                  maxBodyLength: Infinity,
                  url: baseAPIURL+'group/get',
                  headers: { 
                    'Authorization': idToken, 
                    'Content-Type': 'application/json'
                  },
                  data : dataGroup
                };
                
                axios.request(configGroup).then(Result => {
                                        
                    console.log(Result)
                    if(Result.data.error)
                    {
                      setStatus({error:true});
                    }
                    else if(Result.data)
                    {
                      setDetails(Result.data)
                      setStatus({});
                    }
                    else
                    {
                      setDetails(null)
                      setStatus({error:true});
                    }
                  
                }).catch(err => {
                      console.error("handleSubmit")
                      console.error(err)
                      setStatus({error:true});
                });

            }



          
          let dataList = JSON.stringify({
            "limit": limit,
            "group": "groupId" in Params && Params.groupId ? Params.groupId : null
          });

          let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: baseAPIURL+'components/list',
            headers: { 
              'Authorization': idToken, 
              'Content-Type': 'application/json'
            },
            data : dataList
          };
          
          axios.request(config).then(Result => {
                                  
              console.log(Result)
              if(Result.data.error)
              {
                  setStatus({error:true});
              }
              else if(Result.data.list)
              {
                  setList(Result.data.list)
                  setStatus({});
              }
              else
              {
                  setList([])
                  setStatus({error:true});
              }
            
        }).catch(err => {
              console.error("handleSubmit")
              console.error(err)
              setStatus({error:true});
        });

        });
          
      };




  
    useEffect(() => {
      LoadData();
    }, []);


    const ComponentIcon = details && details.icon && details.icon in Icon ? Icon[details.icon] : "";


    if(error)
      return(<Page404/>)

    if(loading)
      return(<Loader/>)



    return (
        <main style={{margin:"30px"}}>



            <Card>
              <Box sx={{m:2,display: "flex"}}>
                {details ? 
                  <>
                    <Box sx={{}}>
                      {ComponentIcon && <ComponentIcon sx={{fontSize:"30px", color:"#02af48ff",  mr:1}}/>}
                    </Box>

                    <h3 style={{flexGrow: 1, marginBottom:0,marginTop: "9px" }}> 
                      {details.name}
                    </h3>
                  </>
                :
                <h3 style={{flexGrow: 1, marginBottom:0,marginTop: "9px" }}> components</h3>
                }

                <Button  sx={{color:"#02af48ff"}} component={NavLink} to="/add/component"><AddIcon/></Button>

              
              </Box>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                        {/*<TableHead>
                            <TableRow>
                            <TableCell>Name</TableCell>
                            </TableRow>
                        </TableHead>*/}
                        <TableBody>
                            

                        {list &&  list.length>0 && list.map((row, index) => {
                            return (

                                <TableRow 
                                    hover 
                                    key={row.device_uuid} 
                                    //onClick={(event) => handleClick(event, row.device_uuid)}
                                    component={NavLink}
                                    to={"/components/"+row.uuid}
                                >
                                    <TableCell>{row.name}</TableCell>
                                </TableRow>
                            )}
                        )}


                        </TableBody>
                        </Table>
                    </TableContainer>


                    <Box sx={{textAlign:"center",p:2}}> 
                        {loadingMore ? "Loading" : <Button onClick={HandleLoadMore}   variant="contained"> Load More</Button>}
                    </Box>

            </Card>        
        </main>
    );
}



const mapStateToParams = (state) => {
    return {
        user:firebase.auth().currentUser,
    }
}

export default compose(
    connect(mapStateToParams)
)(Index);
import React, {Component,useState  } from 'react'
import { NavLink,Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { firestoreConnect } from  'react-redux-firebase'
import { compose } from 'redux'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';


import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

import Page404 from './Page404';
import Loader from "../components/other/Loader"

//import { useNavigate  } from 'react-router-dom'
import {baseAPIURL,downloadAPIURL} from "../store/api/Access"

import  { useEffect  } from 'react';
import  axios  from 'axios'



import * as Icon from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';


const Index = ({user,}) => {


    
    const [limit, setLimit] = React.useState(60);
    const [list, setList] = React.useState(null);
    const [{loading,loadingMore,error}, setStatus] = React.useState({loading:true});
  
    //const navigate = useNavigate();
    

    const HandleLoadMore = () => {

      const NewLimit = limit+30;
      setLimit(NewLimit)
      setStatus({loadingMore:true})
      firebase.auth().currentUser.getIdToken().then(function(idToken) {


          let data = JSON.stringify({
            "limit": NewLimit
          });

          let config = {
            method: 'post',
            //maxBodyLength: Infinity,
            url: baseAPIURL+'group/list',
            headers: { 
              'Authorization': idToken, 
              //'Content-Type': 'application/json'
            },
            data : data
          };
          
          axios.request(config).then(Result => {
                                  
              console.log(Result)
              if(Result.data.error)
              {
                  setStatus({error:true});
              }
              else if(Result.data.list)
              {
                  setList(Result.data.list)
                  setStatus({});
              }
              else
              {
                  setList([])
                  setStatus({error:true});
              }
            
        }).catch(err => {
              console.error("handleSubmit")
              console.error(err)
              setStatus({error:true});
        });
      });



    }



      const LoadData = () => {
          setStatus({loading:true});
          //setList(null)
          console.log("LoadData")
  
          console.log(firebase.auth().currentUser)
          firebase.auth().currentUser.getIdToken().then(function(idToken) {

            let data = JSON.stringify({
              "limit": limit
            });

            let config = {
              method: 'post',
              maxBodyLength: Infinity,
              url: baseAPIURL+'group/list',
              headers: { 
                'Authorization': idToken, 
                'Content-Type': 'application/json'
              },
              data : data
            };
            
            axios.request(config).then(Result => {
                                    
                console.log(Result)
                if(Result.data.error)
                {
                    setStatus({error:true});
                }
                else if(Result.data.list)
                {
                    setList(Result.data.list)
                    setStatus({});
                }
                else
                {
                    setList([])
                    setStatus({error:true});
                }
              
          }).catch(err => {
                console.error("handleSubmit")
                console.error(err)
                setStatus({error:true});
          });
        });
          
      };
  
    useEffect(() => {
      LoadData();
    }, []);

    if(error)
      return(<Page404/>)

    if(loading)
      return(<Loader/>)



        return (
            <main style={{margin:"30px"}}>
                <Grid container spacing={2}>


                    
                {list &&  list.length>0 && list.map((row, index) => {
 
                    const ComponentIcon = row.icon && row.icon in Icon ? Icon[row.icon] : null;
                    return (
                        <Grid item sm={4} lg={2} md={3} xs={12}>
                            <Link  to={"/group/"+row.id}  style={{ textDecoration: 'none' }}>
                                <Card>
                                    <CardContent   sx={{textAlign:"center"}}>
                                        {ComponentIcon && <ComponentIcon   sx={{ fontSize: 40, color:"#02af48ff"}}/>}
                                        <h2 style={{margin:0, }}>{row.name}</h2>
                                    </CardContent>
                                </Card>
                            </Link>
                        </Grid>
                    )}
                )}


                    
                </Grid>

                <Box sx={{textAlign:"center",mt:3}}>
                    <Button component={NavLink} to="/add" className='AddButton' sx={{textAlign:"center", background:"#fff"}}>
                        <AddIcon   sx={{ fontSize: 30, color:"#02af48ff"}}/>
                    </Button>
                </Box>
            </main>
        );
}



const mapStateToProps = (state) => {
    return {
        user:firebase.auth().currentUser,
    }
}

export default compose(
    connect(mapStateToProps)
)(Index);
import React, {Component,useState  } from 'react'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import { firestoreConnect } from  'react-redux-firebase'
import { compose } from 'redux'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DownloadIcon from '@mui/icons-material/Download';
import fileDownload from 'js-file-download';

import { Routes, Route, useParams } from 'react-router-dom';

import {
    Button,
    Box,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    TextField, 
    FormControl,
  Select,
  MenuItem
    
  } from '@mui/material';

  
import CardMenu from "../../../components/components/CardMenu"

//import { useNavigate  } from 'react-router-dom'
import {baseAPIURL,downloadAPIURL} from "../../../store/api/Access"

import  { useEffect  } from 'react';
import  axios  from 'axios'

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

import Page404 from '../../Page404';
import Loader from "../../../components/other/Loader"




  const AccountProfileDetails = (props) => {
    const {user} = props
    const Params = useParams();
    const [values, setValues] = useState({notifications:{}});

    const [DownloadLoading, setDownloadLoading] = React.useState(false);

    const [limit, setLimit] = React.useState(30);
    const [list, setList] = React.useState(null);
    const [{loading,loadingMore,error}, setStatus] = React.useState({loading:true});
  
    const [componentDetails, setComponentDetails] = React.useState(null);
  
    //const navigate = useNavigate();
    //const navigate = useNavigate();
    

    const HandleLoadMore = () => {

      const NewLimit = limit+30;
      setLimit(NewLimit)
      setStatus({loadingMore:true})
      firebase.auth().currentUser.getIdToken().then(function(idToken) {


          let dataComponent = JSON.stringify({
            "limit": NewLimit
          });

          let config = {
            method: 'post',
            //maxBodyLength: Infinity,
            url: baseAPIURL+'files/list',
            headers: {  
              'Authorization': idToken, 
              //'Content-Type': 'application/json'
            },
            data : dataComponent
          };
          
          axios.request(config).then(Result => {
                                  
              console.log(Result)
              if(Result.data.error)
              {
                  setStatus({error:true});
              }
              else if(Result.data.list)
              {
                  setList(Result.data.list)
                  setStatus({});
              }
              else
              {
                  setList([])
                  setStatus({error:true});
              }
            
        }).catch(err => {
              console.error("handleSubmit")
              console.error(err)
              setStatus({error:true});
        });
      });



    }



      const LoadData = () => {
          setStatus({loading:true});
          //setList(null)
          console.log("LoadData")
  
          console.log(firebase.auth().currentUser)
          firebase.auth().currentUser.getIdToken().then(function(idToken) {


            
            let dataComponent = JSON.stringify({
              "id": Params.componentId
            });

            let configComponent = {
              method: 'post',
              maxBodyLength: Infinity,
              url: baseAPIURL+'components/get',
              headers: { 
                'Authorization': idToken, 
                'Content-Type': 'application/json'
              },
              data : dataComponent
            };
            
            axios.request(configComponent).then(Result => {
                                    
                console.log(Result)
                if(Result.data.error)
                {
                    setStatus({error:true});
                }
                else if(Result.data)
                {
                    setComponentDetails(Result.data)
                    setStatus({});
                }
                else
                {
                  setComponentDetails(null)
                  setStatus({error:true});
                }
              
          }).catch(err => {
                console.error("handleSubmit")
                console.error(err)
                setStatus({error:true});
          });





            let data = JSON.stringify({
              "limit": limit
            });

            let config = {
              method: 'post',
              maxBodyLength: Infinity,
              url: baseAPIURL+'files/list',
              headers: { 
                'Authorization': idToken, 
                'Content-Type': 'application/json'
              },
              data : data
            };
            
            axios.request(config).then(Result => {
                                    
                console.log(Result)
                if(Result.data.error)
                {
                    setStatus({error:true});
                }
                else if(Result.data.list)
                {
                    setList(Result.data.list)
                    setStatus({});
                }
                else
                {
                    setList([])
                    setStatus({error:true});
                }
              
          }).catch(err => {
                console.error("handleSubmit")
                console.error(err)
                setStatus({error:true});
          });
        });
          
      };
  
  
  


    
    useEffect(() => {
      LoadData();
    }, []);

    

    
    const handleClick = (event, DeviceUid) => {
      console.log(DeviceUid)

      //navigate("/devices/" + DeviceUid);
    }



    
    const downloadFile = async (uuid,name) =>{
      setDownloadLoading(true)
      const url = downloadAPIURL+uuid

      firebase.auth().currentUser.getIdToken().then(async function(idToken) {

        
        let data= JSON.stringify({
          "componentId": Params.componentId
        });

        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: baseAPIURL+'components/get',
          headers: { 
            'Authorization': idToken, 
            'Content-Type': 'application/json'
          },
          data : data
        };


        axios(config).then(res => {
          console.log(res)
          fileDownload(res.data,name);
          setDownloadLoading(false)
        }).catch(err => {
          console.log(err)
          alert("Failed to download")
          setDownloadLoading(false)
        });
        
      });

      
    }


    




    
    if(error)
      return(<Page404/>)

    if(loading)
      return(<Loader/>)




    return (
      <main >
    

        <CardMenu 
              //subheader="The information can be edited"
              title={componentDetails && componentDetails.name}
        >
          


          <Box sx={{m:2}}>
                <Button component={NavLink} to='/files/upload'  variant="contained"> Upload</Button>
              </Box>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                            <TableCell></TableCell>
                            <TableCell>Name</TableCell>
                            {/*<TableCell align="right">Size</TableCell>*/}
                            <TableCell align="right">Date</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            

                        {list &&  list.length>0 && list.map((row, index) => {
                            return (

                                <TableRow 
                                    hover 
                                    key={row.device_uuid} 
                                    //onClick={(event) => handleClick(event, row.device_uuid)}
                                >
                                  
                                    <TableCell><DownloadIcon  onClick={()=>downloadFile(row.uuid,row.name)} /></TableCell>
                                    <TableCell>{row.name}</TableCell>
                                    {/*<TableCell align="right">{row.size}</TableCell>*/}
                                    <TableCell align="right">{row.date}</TableCell>
                                </TableRow>
                            )}
                        )}


                        </TableBody>
                        </Table>
                    </TableContainer>


                    <Box sx={{textAlign:"center",p:2}}> 
                        {loadingMore ? "Loading" : <Button onClick={HandleLoadMore}   variant="contained"> Load More</Button>}
                    </Box>
            
        </CardMenu>
        
      </main>
    );
  };

  const mapStateToProps = (state) => {

    return {
        //user:firebase.auth().currentUser,
    }
}


export default compose(
    connect(mapStateToProps),
    /*firestoreConnect(props =>{
        return[
            { collection: 'Users', storeAs: 'Profile', doc:props.user.uid},
        ]
    })*/
) (AccountProfileDetails);
import React, {Component,useState  } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { firestoreConnect } from  'react-redux-firebase'
import { compose } from 'redux'


import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    TextField, 
    FormControl,
  Select,
  MenuItem,
  Box,Typography, List, ListItem, ListItemText,useTheme,useMediaQuery
    
  } from '@mui/material';

  import IconButton from '@mui/material/IconButton';
  import PersonIcon from '@mui/icons-material/Person';
  import AppsIcon from '@mui/icons-material/Apps';

import CardMenu from "../../components/settings/CardMenu"

import MenuIcon from '@mui/icons-material/Menu';

  
  const AccountProfileDetails = (props) => {
    const {user} = props
    const [values, setValues] = useState({notifications:{}});



    return (
        <main >
      <form /*onSubmit={handleSubmit}*/ action="post"
        autoComplete="off"
        noValidate
      >

        <CardMenu 
        subheader="The information can be edited"
        title="Profile"
        >
          

            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={12}
                xs={12}
              >
                <TextField
                  fullWidth
                  helperText="Please specify the name"
                  label="Display Name"
                  name="displayName"
                  //onChange={handleChange}
                  required
                  //value={values.displayName}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={12}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Email Address"
                  name="email"
                  //onChange={handleChange}
                  required
                  value={values.email}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={12}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Country"
                  name="country"
                  //onChange={handleChange}
                  required
                  value={values.country}
                  variant="outlined"
                />
              </Grid>
              

              <Grid
                item
                md={12}
                xs={12}
              >

              
            <Button
              type="submit"
              color="primary"
              variant="contained"
            >
              Save details
            </Button>
            </Grid>
            </Grid>
        </CardMenu>
        
    </form>
      </main>
    );
  };

  const mapStateToProps = (state) => {

    return {
        //user:firebase.auth().currentUser,
    }
}


export default compose(
    connect(mapStateToProps),
    /*firestoreConnect(props =>{
        return[
            { collection: 'Users', storeAs: 'Profile', doc:props.user.uid},
        ]
    })*/
) (AccountProfileDetails);
import React, {Children, Component,useState  } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import { firestoreConnect } from  'react-redux-firebase'
import { compose } from 'redux'


import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    TextField, 
    FormControl,
  Select,
  MenuItem,
  Box, Drawer,Typography, List, ListItem, ListItemText,useTheme,useMediaQuery
    
  } from '@mui/material';

  import IconButton from '@mui/material/IconButton';
  import PersonIcon from '@mui/icons-material/Person';
  import AppsIcon from '@mui/icons-material/Apps';



import MenuIcon from '@mui/icons-material/Menu';

  
  const View = ({children,subheader,title}) => {

    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleDrawerToggle = () => {
      setOpen(!open);
    };

    const drawer = (
      <div>
        <List>

            <ListItem component={NavLink} to="/settings" button key={"Profile"}>
              <ListItemText primary={"Profile"} />
            </ListItem>
            <ListItem component={NavLink} to="/settings/notifications" button key={"Notifications"}>
              <ListItemText primary={"Notifications"} />
            </ListItem>
            <ListItem component={NavLink} to="/settings/groups" button key={"Groups"}>
              <ListItemText primary={"Groups"} />
            </ListItem>
            <ListItem component={NavLink} to="/settings/components" button key={"Components"}>
              <ListItemText primary={"Components"} />
            </ListItem>
            <ListItem component={NavLink} to="/settings/permissions" button key={"Permissions"}>
              <ListItemText primary={"Permissions"} />
            </ListItem>
          
        </List>
      </div>
    );




    return (
<Card sx={{m:2, display: 'flex' }}>

  <Box sx={{borderRight:"1px solid #111"}}>
    
    {(!isMobile) && (
      <Box sx={{ width: '240px', transition: theme.transitions.create(['width'], { easing: theme.transitions.easing.sharp, duration: theme.transitions.duration.enteringScreen }) }}>
        {drawer}
      </Box>
    )}
  </Box>


  <CardContent  sx={{flexGrow:1}}>
    <Box sx={{ display: 'flex', pb:1 }}>
      
      {isMobile && (
        <Button onClick={handleDrawerToggle}>
          <MenuIcon sx={{color: "#02af48ff"}} />
        </Button>
      )}

      <Box sx={{flexGrow:1}}>
        {title && 
            <Typography variant="h5" component="div">
              {title}
            </Typography>
        }
        {subheader && 
            <Typography variant="body" component="div">
              {subheader}
            </Typography>
        }
      </Box>
    </Box>
        

    {(open && isMobile) && (
      <Box sx={{ width: '100%', transition: theme.transitions.create(['width'], { easing: theme.transitions.easing.sharp, duration: theme.transitions.duration.enteringScreen }) }}>
      <Divider/>
        {drawer}
      </Box>
    )}

<Divider/>
        
    <Box sx={{flexGrow:1, mt:3}}>
        
      {children}

    </Box>
  </CardContent>

</Card>


    );
  };


export default (View);
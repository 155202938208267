import React, {Component,useState  } from 'react'
import { NavLink,Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';

import { connect } from 'react-redux'
import { firestoreConnect } from  'react-redux-firebase'
import { compose } from 'redux'
import firebase from 'firebase/compat/app'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';

import {baseAPIURL} from "../../store/api/Access"

import  { useEffect  } from 'react';
import  axios  from 'axios'


import * as Icons from '@mui/icons-material';

import Page404 from '../Page404';
import Loader from "../../components/other/Loader"



const Index = ({user,}) => {

    


        return (
            <main style={{margin:"30px"}}>
                <Card>
                    <CardContent >
                        <Grid container spacing={2}>

                            <Grid item sm={12} lg={6} md={6} xs={12} >
                                <Button component={NavLink} to="/add/group" variant="outlined" sx={{textAlign:"center",width:"100%", color:"#02af48ff", bordercolor:"#02af48ff",display: "block;"}} >
                                    <Box><h2 style={{margin:0, display:"block" }}>Add Group</h2></Box>
                                    <Box><p>Groups are Groups of components.</p></Box>
                                </Button>
                            </Grid>

                            <Grid item sm={12} lg={6} md={6} xs={12} >
                                <Button component={NavLink} to="/add/component" variant="outlined" sx={{textAlign:"center",width:"100%", color:"#02af48ff", bordercolor:"#02af48ff",display:" block;"}} >
                                    <Box><h2 style={{margin:0, display:"block"}}>Add Component</h2></Box>
                                    <Box><p>Components are elements to help you mangment your self.</p></Box>
                                </Button>
                            </Grid>

                        </Grid>
                    </CardContent>
                </Card>
            </main>
        );
}



const mapStateToProps = (state) => {
    return {
        user:firebase.auth().currentUser,
    }
}

export default compose(
    connect(mapStateToProps)
)(Index);
import React from 'react'


const Footer = (props) => {


    return null
    /*return (
        <footer className={classes.footer}>
          <div className={classes.copyright}>
              Copyright © 2020 <NavLink to="https://spried.com/">spried.com</NavLink>. All rights reserved.
          </div>
        </footer>
    )*/
}

export default (Footer)